import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { RouterModule, Routes } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { MainComponent } from "./components/main/main.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
// Material
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";

// Interceptors & Guards
import { HTTPErrorInterceptor, AuthInterceptor } from "./http.interceptor";
import { AuthGuard, RoleGuard } from "./guards/auth.guard";
import { LogoutComponent } from "./modules/authentication/pages/logout/logout.component";
import { AuthenticationModule } from "./modules/authentication/authentication.module";
import { MatAutocompleteModule } from "@angular/material";

const routes: Routes = [
  {
    path: "auth",
    loadChildren:
      "./modules/authentication/authentication.module#AuthenticationModule",
  },

  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "company",
        loadChildren: "./modules/company/company.module#CompanyModule",
        canActivateChild: [RoleGuard],
      },
      {
        path: "warehouse",
        loadChildren: "./modules/warehouse/warehouse.module#WarehouseModule",
      },
      {
        path: "inventory",
        loadChildren: "./modules/inventory/inventory.module#InventoryModule",
      },
      {
        path: "device",
        loadChildren: "./modules/device/device.module#DeviceModule",
      },
      {
        path: "subscription",
        loadChildren:
          "./modules/subscription/subscription.module#SubscriptionModule",
      },
      {
        path: "dashboard",
        loadChildren: "./modules/dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "vehicle-make",
        loadChildren:
          "./modules/vehicle-make/vehicle-make.module#VehicleMakeModule",
      },
      {
        path: "vehicle-model",
        loadChildren:
          "./modules/vehicle-model/vehicle-model.module#VehicleModelModule",
      },
      {
        path: "vehicle-type",
        loadChildren:
          "./modules/vehicle-type/vehicle-type.module#VehicleTypeModule",
      },
      {
        path: "device-command",
        loadChildren:
          "./modules/device-command/device-command.module#DeviceCommandModule",
        canActivateChild: [RoleGuard],
      },
      {
        path: "device-commandBulk",
        loadChildren:
          "./modules/device-commandBulk/device-commandBulk.module#DeviceCommandBulkModule",
        canActivateChild: [RoleGuard],
      },
      {
        path: "debugging",
        loadChildren: "./modules/debugging/debugging.module#DebuggingModule",
        canActivateChild: [RoleGuard],
      },
      {
        path: "usermapping",
        loadChildren:
          "./modules/usermapping/usermapping.module#UserMappingModule",
      },
      {
        path: "**",
        redirectTo: "company",
      },
    ],
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  declarations: [AppComponent, MainComponent, NavigationComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true }),
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    FormsModule,
    AuthenticationModule,
    MatDialogModule,
    MatMenuModule,
    MatAutocompleteModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPErrorInterceptor,
      multi: true,
    },
    AuthGuard,
    RoleGuard,
  ],
  bootstrap: [AppComponent],
  entryComponents: [LogoutComponent],
})
export class AppModule {}
