import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationService } from './services/notification.service'

@Injectable()
export class HTTPErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private NotificationService: NotificationService
    ) { }
    intercept(req: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(catchError(err => {
            if (err.status === 401) {
                this.NotificationService.showError(err.error.message)
                localStorage.clear();
                setTimeout(() => {
                    this.router.navigateByUrl('/auth/login');
                }, 3000);
                // auto logout if 401 response returned from api
                // this.authenticationService.logout();
                // location.reload(true);
            }

            const error = err.error || err;
            console.log(err);
            return throwError(error);
        }));
    }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {

        const idToken = localStorage.getItem('idToken');
        if (idToken) {
            const cloned = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${idToken}`)
            });
            return next.handle(cloned);
        } else {
            return next.handle(req);
        }
    }
}

