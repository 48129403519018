import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../../services/authentication.service';
import { NotificationService } from '../../../../services/notification.service';
import * as _moment from 'moment';
const moment = _moment;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public profileForm: FormGroup;
  public isSigning: Boolean = false;
  public year= moment().format('Y');

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthenticationService,
    private notification: NotificationService
  ) { }

  ngOnInit() {
    this.profileForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });

  }


  async onSubmit(form: FormGroup) {
    try {
      this.isSigning = true;
      if (form.invalid) {
        throw new Error('Invalid input. Please correct the fields');
      }
      const response = await this.authService.login(this.profileForm.value);

      if (response['type'] !== 1) {
        throw new Error('This console is restricted to the Application Administrators');
      }

      this.router.navigateByUrl('/company');
      this.isSigning = false;
    } catch (error) {
      this.isSigning = false;
      this.notification.showError(error.message);
    }
  }


}