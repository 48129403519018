import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(
    private router: Router
  ) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = localStorage.getItem('idToken');

    if (!token) {
      this.router.navigateByUrl('/auth/login');
      return false;
    } else {
      return true;
    }

  }
}

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivateChild {

  constructor(
    private router: Router,
    private authorize: AuthenticationService
  ) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (state.url == '/company/unauthorized') {
      return true;
    }
    if (!this.authorize.isAuthorized(state.url)) {
      // role not authorised so redirect to home page
      this.router.navigate(['/company/unauthorized']);
      return false;
    } else {
      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page
    this.router.navigate(['auth/login']);
    return false;
  }
}
