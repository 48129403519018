import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from '../../modules/authentication/pages/logout/logout.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material'


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public triggerReload: Boolean = false;
  public isActive: Boolean = false;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  constructor(private dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    // if(this.router.url == '/vehicle-make/list' || '/vehicle-model/list' || '/vehicle-type/list')
    // this.isActive = true;
  }
  Logout() {
    this.dialog.open(LogoutComponent, {
      width: '300px',
      maxHeight: '300px',

    });

  }
  openMyMenu() {
    this.trigger.openMenu();
  }
  closeMyMenu() {
    this.trigger.closeMenu();
  }
  public closeDialog() {
    this.dialog.closeAll();
  }
}
