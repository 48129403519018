import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private snackbar: MatSnackBar
  ) { }

  public showSuccess(message) {
    this.snackbar.open(message, 'Hide', {
      duration: 3000
    });
  }

  public showError(message) {
    this.snackbar.open(message, 'Close', {
      duration: 4000
    });
  }
}
