import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})


export class AuthenticationService {
  private authorizedPages;
  public credentials = [];

  constructor(private http: HttpClient) { }

  public login(credentials) {
    return this.http.post(`${environment.apiBaseUrl}v2/user/login`, {
      email: credentials.email,
      password: btoa(credentials.password)
    }).pipe(
      map(res => {
        this.persist(res);
        this.acl = res['roles'];
        return res;
      })
    ).toPromise();
  }

  public persist(res) {
    window.localStorage.setItem('idToken', res.token);
    window.localStorage.setItem('pages', JSON.stringify(res.roles));
  }

  set acl(roles) {
    this.authorizedPages = roles;
  };

  get acl() {
    return this.authorizedPages;
  };


  public isAuthorized(route) {
    // return true;
    this.authorizedPages = JSON.parse(window.localStorage.getItem('pages'));
    if (!this.authorizedPages) {
      return false;
    }
    
    //const index = this.authorizedPages.findIndex(page => route.replace(/[0-9]/g, '') == page.PageUrl)
    const index = this.authorizedPages.findIndex(page => route.replace(/[^A-Za-z]/g, '') == page.PageUrl.replace(/[^A-Za-z]/g, ''))
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

}
