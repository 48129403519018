// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //live
  apiBaseUrlNew: "https://api.girfalco.sa/v2/",
  apiBaseUrl: "https://api.girfalco.sa/",
  socketServer: "https://socket.girfalco.sa/",
  customerUrl: "https://customer.girfalco.sa/",
  AIBaseurl : "https://dev-ai-api-service.girfalco.sa:5001/",

  //dev
  // apiBaseUrlNew: "https://dev.api.girfalco.sa/v2/",
  // apiBaseUrl: "https://dev.api.girfalco.sa/",
  // socketServer: "https://dev.socket.girfalco.sa/",
  // customerUrl: "https://customer.girfalco.sa/",
  // AIBaseurl : "https://dev-ai-api-service.girfalco.sa:5001/",

}

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
