import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './pages/login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LogoutComponent } from './pages/logout/logout.component';
import {MatDividerModule} from '@angular/material/divider';
const routes: Routes = [
  {
    path: 'login', component: LoginComponent
  }, {
    path: 'logout', component: LogoutComponent
  }
  
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormsModule,
    MatDividerModule

  ],
  declarations: [LoginComponent, LogoutComponent],
  exports: [LogoutComponent]
})
export class AuthenticationModule { }
